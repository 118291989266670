
// This part is controlling the navigation drawer active items.
// todo: make better names
$primary-color-background: #2196F3;
$primary-color: #2196F3;
$primary-color-accent-text: #efefef;

/* Taken from demo page
https://github.com/google/material-design-lite/blob/master/templates/dashboard/styles.css
*/
html, body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
}

.rating-layout .rating-header .mdl-textfield {
  padding: 0px;
  margin-top: 41px;
}
.rating-layout .rating-header .mdl-textfield .mdl-textfield__expandable-holder {
  bottom: 19px;
}
.rating-layout .mdl-layout__header .mdl-layout__drawer-button {
  color: rgba(0, 0, 0, 0.54);
}
.mdl-layout__drawer .avatar {
  margin-bottom: 16px;
}
.rating-drawer {
  border: none;
}
/* iOS Safari specific workaround */
.rating-drawer .mdl-menu__container {
  z-index: -1;
}
.rating-drawer .rating-navigation {
  z-index: -2;
}
/* END iOS Safari specific workaround */
.rating-drawer .mdl-menu .mdl-menu__item {
  display: flex;
  align-items: center;
}
.rating-drawer-header {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  height: 151px;
}


.rating-navigation {
  flex-grow: 1;
}
.rating-layout .rating-navigation .mdl-navigation__link {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
}
.rating-layout .rating-navigation .mdl-navigation__link:hover {
  background-color: $primary-color-background;
  color: $primary-color-accent-text;
}
.rating-navigation .mdl-navigation__link .material-icons {
  font-size: 24px;
  margin-right: 24px;
}


/* Start of my stuff */

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link.active {
  background-color: $primary-color-background;
  color: $primary-color-accent-text;
}

.mdl-card__title {
  background-color: $primary-color-background;
  color: $primary-color-accent-text;
}

.mdl-card__full-width {
  width: 100%;
}

.mdl-card, .mdl-card__supporting-text {
  overflow: visible;
}

.mdl-textfield__label {
  position: static;
}

.add-item-button {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.quote-summary-item:hover {
  background-color: #eee;
}


#coverage-dialog, #insured-dialog, #location-dialog {
  width: 40%;
  top: 10%;
}


.premium-summary {
  text-align: center;
}
.premium-summary h2 {
  font-size: 16px;
  margin: 0 auto;
}
.premium-summary h3 {
  font-size: 12px;
  margin: 0 auto;
}


.rating-steps-list {
  width: 100%;
}
.rating-steps-list li:nth-child(odd) {
  background-color: #efefef;

}
.rating-steps-list .mdl-list__item {
  padding: 0;
  padding-left: 16px;
}

.rating-step__value {
  margin-right: 1em;
}
.rating-step__running-total {
  margin-right: 1em;
  font-weight: 700;
}
.rating-operator {
  color: #999;
}

.rating-toggle-step {
  cursor: pointer;
}
